import { NextPage, NextPageContext } from 'next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { NextSeo } from 'next-seo';
import React, { useEffect } from 'react';
import { useApp } from 'components/app';
import { checkUser, googleLoginUser } from 'api/ssr/user';
import { RegisterForm } from 'components/common/organisms';
import { JWT, TPSAPI } from '@api/users';
import { IGeoCountries } from 'interface/ILocation';
import { ILoginResponse, IUser } from 'interface';
import { getSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { gtmGoogleCreateAccount } from '@api/gtm/gtm';

type IPageProps = {
  login: boolean;
  countries: IGeoCountries[];
  googleData?: ILoginResponse;
};

const RegisterPage: React.FC<IPageProps> = ({ login, countries, googleData }: IPageProps) => {
  const { setUser } = useApp();
  const router = useRouter();

  useEffect(() => {
    if (login) {
      router.push('/');
    }

    if (googleData) {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.load();
      }
      const responseData = JWT.setJwt(googleData);
      setUser(responseData);
      router.push('/');
    }
  }, [googleData]);

  useEffect(() => {
    if (login) {
      router.push('/');
    }
  }, [login]);

  if (googleData) {
    const getJwtUser = JWT.getJwtUser();
    if(getJwtUser?.id !== '' && getJwtUser?.firstLogin === true) {
      gtmGoogleCreateAccount();
    }

    return (
      <>
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
        var _hsq = window._hsq = window._hsq || [];
          _hsq.push(["identify",{
          email: '${getJwtUser.email}'
        }]);
          window.hsConversationsSettings = {
          loadImmediately: false,
          identificationEmail: '${getJwtUser.email}'
        };
        

  `,
          }}
        />
        <div className="loader-container-gif">
          <img src="/images/logo-loader.gif" alt="this slowpoke moves" width="40%" />
        </div>
      </>
    );
  }

  return (
    <>
      <NextSeo title="Join | Blindspot" description="" />
      <GoogleReCaptchaProvider
        reCaptchaKey="6LdEhl4mAAAAAC7LIm769WhF8oRx9KMfhFT86-Tc"
        scriptProps={{
          async: false, // optional, default to false,
          defer: true, // optional, default to false
          appendTo: 'body', // optional, default to "head", can be "head" or "body",
          nonce: undefined,
        }}
      >
        <RegisterForm countries={countries}></RegisterForm>
      </GoogleReCaptchaProvider>
    </>
  );
};

export const getServerSideProps = async (_ctx: NextPageContext) => {
  const refId: string = String(_ctx.query.refId);
  const isLogin = await checkUser(_ctx);
  const geoCountries = await TPSAPI.getGeoCountries(_ctx);
  const data = await getSession(_ctx);
  let googleData = null;
  if (data?.user) {
    const req = _ctx.req;
    let ip: any = '';
    if (req) {
      const foundIp =
        req?.headers['x-forwarded-for'] || req?.socket.remoteAddress || req?.connection.remoteAddress || 'NONE';
      if (foundIp) {
        const ips = (typeof foundIp === 'string' ? [foundIp] : foundIp) as string[];
        ip = ips[0];
      }
    }

    googleData = await googleLoginUser(
      {
        email: data.user?.email as string,
        name: data.user?.name as string,
        ip,
        refid: refId ? refId : undefined,
      },
      _ctx
    );
  }

  if (!isLogin) {
    return {
      props: {
        login: false,
        countries: geoCountries,
        googleData,
      },
    };
  } else {
    return {
      props: {
        login: true,
        countries: geoCountries,
        googleData,
      },
    };
  }
};

export default RegisterPage;
